import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TuiButtonModule } from '@taiga-ui/core';

@Component({
  selector: 'knk-default-dialog',
  standalone: true,
  imports: [CommonModule, TuiButtonModule, TranslateModule],
  templateUrl: './default-dialog.component.html',
  styleUrl: './default-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultDialogComponent {
  readonly header = input.required<string>();

  readonly cancelLabel = input.required<string>();

  readonly doneLabel = input.required<string>();

  readonly doneEnabled = input(true);

  readonly done = output<void>();

  readonly cancel = output<void>();
}
