import { Injectable } from '@angular/core';
import { AbstractTuiValueTransformer, TuiDay, TuiTime } from '@taiga-ui/cdk';

@Injectable()
export class TuiDateTimeToNativeDateTransformer extends AbstractTuiValueTransformer<
  [TuiDay | null, TuiTime | null],
  Date | null
> {
  fromControlValue(controlValue: Date): [TuiDay | null, TuiTime | null] {
    return controlValue
      ? [
          TuiDay.fromLocalNativeDate(controlValue),
          TuiTime.fromLocalNativeDate(controlValue)
        ]
      : [null, null];
  }

  toControlValue([day, time]: [TuiDay | null, TuiTime | null]): Date | null {
    let result: Date | null = null;
    if (day) {
      result = day.toLocalNativeDate();
      if (time) {
        result.setHours(time.hours);
        result.setMinutes(time.minutes);
        result.setSeconds(time.seconds);
      }
    }
    return result;
  }
}
