import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@konnektu/components';
import { TuiAutoFocusModule } from '@taiga-ui/cdk';
import { TuiButtonModule } from '@taiga-ui/core';
import { TuiInputInlineModule } from '@taiga-ui/kit';

@Component({
  selector: 'knk-toggle-inline-input',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TuiButtonModule,
    TuiAutoFocusModule,
    TuiInputInlineModule
  ],
  templateUrl: './toggle-inline-input.component.html',
  styleUrl: './toggle-inline-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ToggleInlineInputComponent
    }
  ]
})
export class ToggleInlineInputComponent extends ControlValueAccessor<string> {
  protected readonly isEditEnabled = signal(false);

  protected readonly value = signal('');

  protected editFocusChange(focused: boolean) {
    if (!focused) {
      this.isEditEnabled.update(() => false);
    }
  }

  protected toggleEdit() {
    this.isEditEnabled.update((prev) => !prev);
  }

  handleInput(val: string) {
    this.value.set(val);
    this.onTouched();
    this.onChanged(val);
  }

  override writeValue(value: string) {
    this.value.set(value);
  }
}
