import { Provider } from '@angular/core';
import { TuiSafeHtml } from '@taiga-ui/cdk';
import { TUI_SANITIZER, tuiSvgSrcInterceptors } from '@taiga-ui/core';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { ICON_MAPPER } from './icon-mapper';

export function provideKonnektuTaigaIcons(): Provider[] {
  return [
    {
      provide: TUI_SANITIZER,
      useClass: NgDompurifySanitizer
    },
    tuiSvgSrcInterceptors((src: TuiSafeHtml) =>
      String(src).startsWith('knk')
        ? `assets/icons/${ICON_MAPPER[String(src)]}.svg`
        : src
    )
  ];
}
