import { importProvidersFrom, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TuiPreviewModule } from '@taiga-ui/addon-preview';
import {
  TUI_ALERT_POSITION,
  TuiAlertModule,
  TuiDialogModule,
  TuiRootModule,
  tuiNotificationOptionsProvider
} from '@taiga-ui/core';
import {
  TUI_ENGLISH_LANGUAGE,
  TUI_LANGUAGE,
  TUI_RUSSIAN_LANGUAGE,
  TuiLanguage
} from '@taiga-ui/i18n';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { provideKonnektuTaigaIcons } from './icons/tui-konnektu-icons.module';

function tuiLanguageFactory(): Observable<TuiLanguage> {
  const translate = inject(TranslateService);
  const getLanguage = (lang: string): TuiLanguage =>
    lang === 'ru' ? TUI_RUSSIAN_LANGUAGE : TUI_ENGLISH_LANGUAGE;

  return translate.onLangChange.pipe(
    map((event) => getLanguage(event.lang)),
    startWith(getLanguage(translate.currentLang))
  );
}

export function provideKonnektuTaiga() {
  return [
    importProvidersFrom(
      TuiRootModule,
      TuiDialogModule,
      TuiAlertModule,
      TuiPreviewModule
    ),
    provideKonnektuTaigaIcons(),
    tuiNotificationOptionsProvider({
      size: 'l',
      icon: 'tuiIconHelpCircle'
    }),
    { provide: TUI_ALERT_POSITION, useValue: '2rem auto' },
    {
      provide: TUI_LANGUAGE,
      useFactory: tuiLanguageFactory
    }
  ];
}
