<div class="tui-space_bottom-4 tui-text_h6">
  {{ header() }}
</div>

<ng-content></ng-content>

<div class="bottom-controls tui-space_top-4">
  <button class="tui-space_right-2" tuiButton appearance="secondary" (click)="cancel.emit()">
    {{ cancelLabel() }}
  </button>
  <button [disabled]="!doneEnabled()" tuiButton (click)="done.emit()">{{ doneLabel() }}</button>
</div>
