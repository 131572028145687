@if (isEditEnabled()) {
  <tui-input-inline
    class="text-xl"
    tuiAutoFocus
    [ngModel]="value()"
    (ngModelChange)="handleInput($event)"
    (focusedChange)="editFocusChange($event)"
    (keydown.enter.prevent)="toggleEdit()"
    (keydown.esc.prevent)="toggleEdit()"
  >
  </tui-input-inline>
} @else {
  <div [title]="value()" class="text-xl text-ellipsis overflow-hidden">
    {{ value() }}
  </div>
  <button
    class="ml-1"
    appearance="icon"
    icon="tuiIconEdit2"
    size="xs"
    tuiIconButton
    type="button"
    (click)="toggleEdit()"
  ></button>
}
