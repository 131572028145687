import { TuiCountryIsoCode } from '@taiga-ui/i18n/enums';

const TUI_RUSSIAN_LANGUAGE_ADDON_COMMERCE = {
    cardNumber: ['Номер', 'Номер карты'],
    cardExpiry: ['Срок', 'Срок действия'],
};

const TUI_RUSSIAN_LANGUAGE_ADDON_EDITOR = {
    colorSelectorModeNames: ['Сплошной цвет', 'Градиент'],
    toolbarTools: {
        undo: 'Отменить',
        redo: 'Повторить',
        font: 'Шрифт',
        fontStyle: 'Стиль шрифта',
        fontSize: 'Размер шрифта',
        bold: 'Полужирный',
        italic: 'Курсив',
        underline: 'Подчеркнутый',
        strikeThrough: 'Зачеркнутый',
        justify: 'Выравнивание',
        justifyLeft: 'Выровнять текст по левому краю',
        justifyCenter: 'Выровнять текст по центру',
        justifyRight: 'Выровнять текст по правому краю',
        justifyFull: 'Выровнять по ширине',
        list: 'Списки',
        indent: 'Увеличить отступ',
        outdent: 'Уменьшить отступ',
        unorderedList: 'Маркированный список',
        orderedList: 'Нумерованный список',
        quote: 'Цитата',
        foreColor: 'Цвет: Текст',
        hiliteColor: 'Цвет выделения',
        backColor: 'Цвет фона',
        clear: 'Сбросить форматирование',
        link: 'Ссылка',
        attach: 'Вставить файл',
        tex: 'Вставить формулу',
        code: 'Code',
        image: 'Вставить изображение',
        insertHorizontalRule: 'Разделитель',
        superscript: 'Надстрочный',
        subscript: 'Подстрочный',
        insertTable: 'Добавить таблицу',
        insertGroup: 'Добавить группу',
        hiliteGroup: 'Покрасить группу',
        removeGroup: 'Удалить группу',
        insertAnchor: 'Добавить якорь',
        mergeCells: 'Объединить ячейки',
        splitCells: 'Разъединить ячейки',
        rowsColumnsManaging: 'Управление строками и столбцами',
        cellColor: 'Цвет ячейки',
        setDetails: 'Аккордеон',
        removeDetails: 'Удалить аккордеон',
    },
    editorEditLink: {
        urlExample: 'example.com',
        anchorExample: 'якорь',
    },
    editorTableCommands: [
        ['Добавить столбец слева', 'Добавить столбец справа'],
        ['Добавить строку сверху', 'Добавить строку снизу'],
        ['Удалить столбец', 'Удалить строку'],
    ],
    editorCodeOptions: ['Код', 'Код в блоке'],
    editorFontOptions: {
        small: 'Мелкий',
        normal: 'Обычный',
        large: 'Крупный',
        title: 'Заголовок',
        subtitle: 'Подзаголовок',
    },
};

const TUI_RUSSIAN_LANGUAGE_ADDON_PREVIEW = {
    previewTexts: {
        rotate: 'Повернуть',
    },
    zoomTexts: {
        zoomIn: 'Увеличить масштаб',
        zoomOut: 'Уменьшить масштаб',
        reset: 'Сбросить масштаб',
    },
};

const TUI_RUSSIAN_LANGUAGE_ADDON_TABLE = {
    showHideText: 'Показать/Скрыть',
    paginationTexts: {
        pages: 'Страниц',
        linesPerPage: 'Строк на страницу',
        of: 'из',
    },
};

const TUI_RUSSIAN_LANGUAGE_COUNTRIES = {
    [TuiCountryIsoCode.AD]: 'Андорра',
    [TuiCountryIsoCode.AE]: 'Объединенные Арабские Эмираты',
    [TuiCountryIsoCode.AF]: 'Афганистан',
    [TuiCountryIsoCode.AG]: 'Антигуа и Барбуда',
    [TuiCountryIsoCode.AI]: 'Ангилья',
    [TuiCountryIsoCode.AL]: 'Албания',
    [TuiCountryIsoCode.AM]: 'Армения',
    [TuiCountryIsoCode.AO]: 'Ангола',
    [TuiCountryIsoCode.AR]: 'Аргентина',
    [TuiCountryIsoCode.AT]: 'Австрия',
    [TuiCountryIsoCode.AU]: 'Австралия',
    [TuiCountryIsoCode.AW]: 'Аруба',
    [TuiCountryIsoCode.AZ]: 'Азербайджан',
    [TuiCountryIsoCode.BA]: 'Босния и Герцеговина',
    [TuiCountryIsoCode.BB]: 'Барбадос',
    [TuiCountryIsoCode.BD]: 'Бангладеш',
    [TuiCountryIsoCode.BE]: 'Бельгия',
    [TuiCountryIsoCode.BF]: 'Буркина Фасо',
    [TuiCountryIsoCode.BG]: 'Болгария',
    [TuiCountryIsoCode.BH]: 'Бахрейн',
    [TuiCountryIsoCode.BI]: 'Бурунди',
    [TuiCountryIsoCode.BJ]: 'Бенин',
    [TuiCountryIsoCode.BL]: 'Сен-Бартельми',
    [TuiCountryIsoCode.BM]: 'Бермудские острова',
    [TuiCountryIsoCode.BN]: 'Бруней-Даруссалам',
    [TuiCountryIsoCode.BO]: 'Боливия',
    [TuiCountryIsoCode.BQ]: 'Бонайре, Синт-Эстатиус и Саба',
    [TuiCountryIsoCode.BR]: 'Бразилия',
    [TuiCountryIsoCode.BS]: 'Багамские Острова',
    [TuiCountryIsoCode.BT]: 'Бутан',
    [TuiCountryIsoCode.BW]: 'Ботсвана',
    [TuiCountryIsoCode.BY]: 'Беларусь (Белоруссия)',
    [TuiCountryIsoCode.BZ]: 'Белиз',
    [TuiCountryIsoCode.CA]: 'Канада',
    [TuiCountryIsoCode.CD]: 'Дем. Респ. Конго (Киншаса)',
    [TuiCountryIsoCode.CF]: 'Центральноафриканская Республика',
    [TuiCountryIsoCode.CG]: 'Конго (Браззавиль)',
    [TuiCountryIsoCode.CH]: 'Швейцария',
    [TuiCountryIsoCode.CI]: 'Кот-д’Ивуар',
    [TuiCountryIsoCode.CL]: 'Чили',
    [TuiCountryIsoCode.CM]: 'Камерун',
    [TuiCountryIsoCode.CN]: 'Китайская Н.Р.',
    [TuiCountryIsoCode.CO]: 'Колумбия',
    [TuiCountryIsoCode.CR]: 'Коста-Рика',
    [TuiCountryIsoCode.CU]: 'Куба',
    [TuiCountryIsoCode.CV]: 'Кабо-Верде',
    [TuiCountryIsoCode.CW]: 'Кюрасао',
    [TuiCountryIsoCode.CY]: 'Кипр',
    [TuiCountryIsoCode.CZ]: 'Чехия',
    [TuiCountryIsoCode.DE]: 'Германия',
    [TuiCountryIsoCode.DJ]: 'Джибути',
    [TuiCountryIsoCode.DK]: 'Дания',
    [TuiCountryIsoCode.DM]: 'Доминика',
    [TuiCountryIsoCode.DO]: 'Доминиканская Республика',
    [TuiCountryIsoCode.DZ]: 'Алжир',
    [TuiCountryIsoCode.EC]: 'Эквадор ',
    [TuiCountryIsoCode.EE]: 'Эстония ',
    [TuiCountryIsoCode.EG]: 'Египет',
    [TuiCountryIsoCode.ER]: 'Эритрея',
    [TuiCountryIsoCode.ES]: 'Испания',
    [TuiCountryIsoCode.ET]: 'Эфиопия',
    [TuiCountryIsoCode.FI]: 'Финляндия',
    [TuiCountryIsoCode.FJ]: 'Фиджи',
    [TuiCountryIsoCode.FK]: 'Фолклендские острова',
    [TuiCountryIsoCode.FM]: 'Ф.Ш. Микронезии',
    [TuiCountryIsoCode.FR]: 'Франция',
    [TuiCountryIsoCode.GA]: 'Габон',
    [TuiCountryIsoCode.GB]: 'Великобритания',
    [TuiCountryIsoCode.GD]: 'Гренада',
    [TuiCountryIsoCode.GE]: 'Грузия',
    [TuiCountryIsoCode.GF]: 'Гайана',
    [TuiCountryIsoCode.GH]: 'Гана',
    [TuiCountryIsoCode.GI]: 'Гибралтар',
    [TuiCountryIsoCode.GL]: 'Гренландия',
    [TuiCountryIsoCode.GM]: 'Гамбия',
    [TuiCountryIsoCode.GN]: 'Гвинея',
    [TuiCountryIsoCode.GP]: 'Гваделупа',
    [TuiCountryIsoCode.GQ]: 'Экваториальная Гвинея',
    [TuiCountryIsoCode.GR]: 'Греция',
    [TuiCountryIsoCode.GT]: 'Гватемала',
    [TuiCountryIsoCode.GW]: 'Гвинея-Бисау',
    [TuiCountryIsoCode.GY]: 'Гайана',
    [TuiCountryIsoCode.HK]: 'Гонконг',
    [TuiCountryIsoCode.HN]: 'Гондурас',
    [TuiCountryIsoCode.HR]: 'Хорватия',
    [TuiCountryIsoCode.HT]: 'Гаити',
    [TuiCountryIsoCode.HU]: 'Венгрия',
    [TuiCountryIsoCode.ID]: 'Индонезия ',
    [TuiCountryIsoCode.IE]: 'Ирландия',
    [TuiCountryIsoCode.IL]: 'Израиль',
    [TuiCountryIsoCode.IN]: 'Индия',
    [TuiCountryIsoCode.IQ]: 'Ирак',
    [TuiCountryIsoCode.IR]: 'Иран',
    [TuiCountryIsoCode.IS]: 'Исландия',
    [TuiCountryIsoCode.IT]: 'Италия',
    [TuiCountryIsoCode.JM]: 'Ямайка',
    [TuiCountryIsoCode.JO]: 'Иордания',
    [TuiCountryIsoCode.JP]: 'Япония ',
    [TuiCountryIsoCode.KE]: 'Кения',
    [TuiCountryIsoCode.KG]: 'Киргизия',
    [TuiCountryIsoCode.KH]: 'Камбоджа',
    [TuiCountryIsoCode.KM]: 'Коморы',
    [TuiCountryIsoCode.KN]: 'Сент-Китс и Невис',
    [TuiCountryIsoCode.KP]: 'Корейская НДР',
    [TuiCountryIsoCode.KR]: 'Респ. Корея',
    [TuiCountryIsoCode.KW]: 'Кувейт',
    [TuiCountryIsoCode.KY]: 'Каймановы острова',
    [TuiCountryIsoCode.KZ]: 'Казахстан',
    [TuiCountryIsoCode.LA]: 'Лаос',
    [TuiCountryIsoCode.LB]: 'Ливан ',
    [TuiCountryIsoCode.LC]: 'Сент-Люсия',
    [TuiCountryIsoCode.LI]: 'Лихтенштейн',
    [TuiCountryIsoCode.LK]: 'Шри-Ланка',
    [TuiCountryIsoCode.LR]: 'Либерия',
    [TuiCountryIsoCode.LS]: 'Лесото',
    [TuiCountryIsoCode.LT]: 'Литва',
    [TuiCountryIsoCode.LU]: 'Люксембург',
    [TuiCountryIsoCode.LV]: 'Латвия',
    [TuiCountryIsoCode.LY]: 'Ливия',
    [TuiCountryIsoCode.MA]: 'Марокко',
    [TuiCountryIsoCode.MC]: 'Монако',
    [TuiCountryIsoCode.MD]: 'Молдова',
    [TuiCountryIsoCode.ME]: 'Черногория',
    [TuiCountryIsoCode.MF]: 'Сен-Мартен',
    [TuiCountryIsoCode.MG]: 'Мадагаскар',
    [TuiCountryIsoCode.MK]: 'Респ. Северная Македония',
    [TuiCountryIsoCode.ML]: 'Мали',
    [TuiCountryIsoCode.MM]: 'Бирма (Мьянма)',
    [TuiCountryIsoCode.MN]: 'Монголия',
    [TuiCountryIsoCode.MO]: 'Макао',
    [TuiCountryIsoCode.MQ]: 'Мартиника',
    [TuiCountryIsoCode.MR]: 'Мавритания',
    [TuiCountryIsoCode.MS]: 'Монтсеррат',
    [TuiCountryIsoCode.MT]: 'Мальта',
    [TuiCountryIsoCode.MU]: 'Маврикий',
    [TuiCountryIsoCode.MV]: 'Мальдивские острова',
    [TuiCountryIsoCode.MW]: 'Малави',
    [TuiCountryIsoCode.MX]: 'Мексика',
    [TuiCountryIsoCode.MY]: 'Малайзия',
    [TuiCountryIsoCode.MZ]: 'Мозамбик',
    [TuiCountryIsoCode.NA]: 'Намибия',
    [TuiCountryIsoCode.NC]: 'Новая Каледония',
    [TuiCountryIsoCode.NE]: 'Нигер',
    [TuiCountryIsoCode.NG]: 'Нигерия',
    [TuiCountryIsoCode.NI]: 'Никарагуа',
    [TuiCountryIsoCode.NL]: 'Нидерланды',
    [TuiCountryIsoCode.NO]: 'Норвегия',
    [TuiCountryIsoCode.NP]: 'Непал',
    [TuiCountryIsoCode.NZ]: 'Новая Зеландия',
    [TuiCountryIsoCode.OM]: 'Оман',
    [TuiCountryIsoCode.PA]: 'Панама',
    [TuiCountryIsoCode.PE]: 'Перу',
    [TuiCountryIsoCode.PF]: 'Французская Полинезия (Таити)',
    [TuiCountryIsoCode.PG]: 'Папуа-Новая Гвинея',
    [TuiCountryIsoCode.PH]: 'Филиппины',
    [TuiCountryIsoCode.PK]: 'Пакистан',
    [TuiCountryIsoCode.PL]: 'Польша',
    [TuiCountryIsoCode.PT]: 'Португалия',
    [TuiCountryIsoCode.PW]: 'Палау',
    [TuiCountryIsoCode.PY]: 'Парагвай',
    [TuiCountryIsoCode.QA]: 'Катар',
    [TuiCountryIsoCode.RE]: 'Реюньон',
    [TuiCountryIsoCode.RO]: 'Румыния',
    [TuiCountryIsoCode.RS]: 'Сербия',
    [TuiCountryIsoCode.RU]: 'Россия',
    [TuiCountryIsoCode.RW]: 'Руанда',
    [TuiCountryIsoCode.SA]: 'Саудовская Аравия ',
    [TuiCountryIsoCode.SB]: 'Соломоновы Острова ',
    [TuiCountryIsoCode.SC]: 'Сейшелы',
    [TuiCountryIsoCode.SD]: 'Судан',
    [TuiCountryIsoCode.SE]: 'Швеция',
    [TuiCountryIsoCode.SG]: 'Сингапур',
    [TuiCountryIsoCode.SH]: 'Остров Святой Елены',
    [TuiCountryIsoCode.SI]: 'Словения',
    [TuiCountryIsoCode.SK]: 'Словакия',
    [TuiCountryIsoCode.SL]: 'Сьерра-Леоне',
    [TuiCountryIsoCode.SM]: 'Сан-Марино',
    [TuiCountryIsoCode.SN]: 'Сенегал',
    [TuiCountryIsoCode.SO]: 'Сомали',
    [TuiCountryIsoCode.SR]: 'Суринам ',
    [TuiCountryIsoCode.ST]: 'Сан-Томе и Принсипи',
    [TuiCountryIsoCode.SV]: 'Сальвадор',
    [TuiCountryIsoCode.SX]: 'Синт-Мартен',
    [TuiCountryIsoCode.SY]: 'Сирийская арабская республика',
    [TuiCountryIsoCode.SZ]: 'Свазиленд',
    [TuiCountryIsoCode.TC]: 'Тёркс и Кайкос',
    [TuiCountryIsoCode.TD]: 'Чад',
    [TuiCountryIsoCode.TG]: 'Того',
    [TuiCountryIsoCode.TH]: 'Таиланд ',
    [TuiCountryIsoCode.TJ]: 'Таджикистан',
    [TuiCountryIsoCode.TL]: 'Восточный Тимор',
    [TuiCountryIsoCode.TM]: 'Туркменистан',
    [TuiCountryIsoCode.TN]: 'Тунис',
    [TuiCountryIsoCode.TO]: 'Тонга',
    [TuiCountryIsoCode.TR]: 'Турция',
    [TuiCountryIsoCode.TT]: 'Тринидад и Тобаго',
    [TuiCountryIsoCode.TW]: 'Тайвань',
    [TuiCountryIsoCode.TZ]: 'Танзания',
    [TuiCountryIsoCode.UA]: 'Украина',
    [TuiCountryIsoCode.UG]: 'Уганда',
    [TuiCountryIsoCode.US]: 'США',
    [TuiCountryIsoCode.UY]: 'Уругвай',
    [TuiCountryIsoCode.UZ]: 'Узбекистан',
    [TuiCountryIsoCode.VC]: 'Сент-Винсент и Гренадины',
    [TuiCountryIsoCode.VE]: 'Венесуэла',
    [TuiCountryIsoCode.VG]: 'Британские Виргинские острова',
    [TuiCountryIsoCode.VN]: 'Вьетнам',
    [TuiCountryIsoCode.VU]: 'Вануату ',
    [TuiCountryIsoCode.WS]: 'Самоа',
    [TuiCountryIsoCode.YE]: 'Йемен ',
    [TuiCountryIsoCode.YT]: 'Майотта',
    [TuiCountryIsoCode.ZA]: 'Южно-Африканская Респ.',
    [TuiCountryIsoCode.ZM]: 'Замбия',
    [TuiCountryIsoCode.ZW]: 'Зимбабве',
    [TuiCountryIsoCode.XK]: 'Косово',
};

const TUI_RUSSIAN_LANGUAGE_CORE = {
    months: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ],
    close: 'Закрыть',
    nothingFoundMessage: 'Ничего не найдено',
    defaultErrorMessage: 'Поле заполнено неверно',
    spinTexts: ['Предыдущий', 'Следующий'],
    shortWeekDays: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
    countries: TUI_RUSSIAN_LANGUAGE_COUNTRIES,
};

const TUI_RUSSIAN_LANGUAGE_KIT = {
    cancel: 'Отменить',
    done: 'Готово',
    more: 'Еще',
    otherDate: 'Другая дата...',
    showAll: 'Показать все',
    hide: 'Скрыть',
    mobileCalendarTexts: ['Выберите день', 'Выберите период', 'Выбрать дни'],
    range: ['от', 'до'],
    countTexts: ['Плюс', 'Минус'],
    time: {
        'HH:MM': 'ЧЧ:ММ',
        'HH:MM:SS': 'ЧЧ:ММ:СС',
        'HH:MM:SS.MSS': 'ЧЧ:ММ:СС.МСС',
    },
    dateTexts: {
        DMY: 'дд.мм.гггг',
        MDY: 'мм.дд.гггг',
        YMD: 'гггг.мм.дд',
    },
    digitalInformationUnits: ['Б', 'КиБ', 'МиБ'],
    passwordTexts: ['Показать пароль', 'Скрыть пароль'],
    copyTexts: ['Копировать', 'Скопировано'],
    shortCalendarMonths: [
        'Янв',
        'Фев',
        'Март',
        'Апр',
        'Май',
        'Июнь',
        'Июль',
        'Авг',
        'Сен',
        'Окт',
        'Нояб',
        'Дек',
    ],
    pagination: ['Предыдущая страница', 'Следующая страница'],
    fileTexts: {
        loadingError: 'Ошибка загрузки',
        preview: 'Предварительный просмотр',
        remove: 'Удалить',
    },
    inputFileTexts: {
        defaultLabelSingle: 'или перетащите\u00A0его\u00A0сюда',
        defaultLabelMultiple: 'или перетащите\u00A0их\u00A0сюда',
        defaultLinkSingle: 'Выберите файл',
        defaultLinkMultiple: 'Выберите файлы',
        maxSizeRejectionReason: 'Файл превышает объем ',
        formatRejectionReason: 'Неверный формат файла',
        drop: 'Поместите файл сюда',
        dropMultiple: 'Поместите файлы сюда',
    },
    multiSelectTexts: {
        all: 'Выбрать все',
        none: 'Снять выбор',
    },
    prompt: {
        yes: 'Да',
        no: 'Нет',
    },
};

const TUI_RUSSIAN_LANGUAGE = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ name: 'russian' }, TUI_RUSSIAN_LANGUAGE_CORE), TUI_RUSSIAN_LANGUAGE_KIT), TUI_RUSSIAN_LANGUAGE_ADDON_COMMERCE), TUI_RUSSIAN_LANGUAGE_ADDON_TABLE), TUI_RUSSIAN_LANGUAGE_ADDON_EDITOR), TUI_RUSSIAN_LANGUAGE_ADDON_PREVIEW);

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_RUSSIAN_LANGUAGE, TUI_RUSSIAN_LANGUAGE_ADDON_COMMERCE, TUI_RUSSIAN_LANGUAGE_ADDON_EDITOR, TUI_RUSSIAN_LANGUAGE_ADDON_PREVIEW, TUI_RUSSIAN_LANGUAGE_ADDON_TABLE, TUI_RUSSIAN_LANGUAGE_CORE, TUI_RUSSIAN_LANGUAGE_COUNTRIES, TUI_RUSSIAN_LANGUAGE_KIT };

